const { axiosCreate } = require('./Api')
import store from '../state/store'
import { getCart } from './CartService'

async function getReservations() {
  const result = await axiosCreate.get('reservationOrders', {
    params: {
      CustomerID: store.getters.customerID,
      Orderby: 'DateTimeExpectedStart',
      SortReverse: true
    },
    headers: { authorization: store.getters.token }
  })

  return result.data.Collection
}

async function cancelReservation({ reservationID }) {
  const result = await axiosCreate.post(
    `reservationorders/${reservationID}/cancel`,
    {
      Reason: 'Geannuleerd vanuit gebruikersdashboard'
    },
    {
      params: { ContactID: store.getters.contactID },
      headers: { authorization: store.getters.token }
    }
  )

  return result
}

async function getReservationItem({ reservationID }) {
  const result = await axiosCreate.get(`reservationOrders/${reservationID}`, {
    params: { ContactID: store.getters.contactID },
    headers: { authorization: store.getters.token }
  })

  return result.data
}

async function getReservationReport({
  reservationOrderID,
  reportID,
  attachmentToken,
  fileName
}) {
  window.open(
    `${process.env.VUE_APP_URL}/api/v1/reservationOrders/${reservationOrderID}/documents/${reportID}/${attachmentToken}/${fileName}`
  )

  return null
}

async function addReservationToCart({ reservationID }) {
  const result = await axiosCreate.post(
    `custReservationOrders/${reservationID}/addToCart/${store.getters.contactID}`,
    {},
    {
      headers: {
        authorization: store.getters.token
      }
    }
  )
  await getCart()
  return result
}

async function getReservationItems({ reservationID }) {
  const result = await axiosCreate.get(
    `reservationorders/${reservationID}/items`,
    {
      headers: { authorization: store.getters.token }
    }
  )

  return result.data.Collection
}

async function getPaymentStatus({ reservationID }) {
  const result = await axiosCreate.get(`custPublicOrderController`, {
    params: { orderID: reservationID },
    headers: { authorization: store.getters.token }
  })

  return result
}

async function updatePaymentStatus({
  reservationID,
  secretCode,
  orderID,
  payID
}) {
  const result = await axiosCreate.patch(
    `custPublicOrderController/${reservationID}/updatepaymentstatus`,
    {
      secretCode,
      orderID,
      payID
    },
    {
      headers: { authorization: store.getters.token }
    }
  )

  return result
}

async function cancelReservationItem({
  reservationOrderID,
  reservationItemID
}) {
  const result = await axiosCreate.post(
    `custReservationOrders/${reservationOrderID}/items/${reservationItemID}/cancel`,
    {},
    {
      headers: { authorization: store.getters.token }
    }
  )

  return result
}

module.exports = {
  getReservations,
  getReservationItems,
  getReservationItem,
  getReservationReport,
  addReservationToCart,
  cancelReservation,
  cancelReservationItem,
  getPaymentStatus,
  updatePaymentStatus
}
